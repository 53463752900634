<template>
  <div>
    <div class="col-12 row justify-center q-ma-md q-mb-xl">
      <div class="col-12 row justify-center q-mb-lg">
        <div class="text-h5 q-mb-md text-amber-5"> {{$t('lvplaner.addlv_addlv')}}</div>
        <div class="col-12 row justify-center">
          <q-select v-model="model" clearable outlined use-input hide-dropdown-icon :popup-content-style="{ 'width': '300px' }" type="search" :label="$t('lvplaner.addlv_search')" class="search-field"
            :hint="$t('lvplaner.addlv_search_hint')" dense :options="filteredOptions" @filter="filterFn" style="width: 300px">
            <template v-slot:append>
              <q-btn round dense flat icon="search" />
            </template>
            <template v-slot:option="scope">
              <q-item clickable v-ripple @click="getCourse(scope.label)">
                <q-item-section>{{ scope.label }}</q-item-section>
              </q-item>
            </template>
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  {{$t('lvplaner.addlv_no_results')}}
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
      </div>
      <div class="col-xs-12 col-lg-6 row">
        <div class="col-12 row shadow-1 q-col-gutter-sm q-pa-md q-mb-lg">
          <div class="col-12 text-h5 text-center text-blue-7 q-mb-lg">
            {{$t('lvplaner.addlv_selected_lvs')}}
          </div>
          <div class="col-xs-12 col-md-auto" v-for="(lv, i) in this.lvStore.cart" :key="i">
            <q-btn @click="addToSelectedLv(lv.name)" :class="{ 'selected-lv': selectedLv.includes(lv.name) }">
              <div class="badge">
                <q-btn round color="red-3" icon="close" size="xs" style="opacity: 0.8"
                  @click.stop="removeLvFromCart(i, lv.name)" />
              </div>
              {{ lv.name }}
            </q-btn>
          </div>
          <div v-if="this.lvStore.cart.length == 0" class="col-12 text-center">
            {{$t('lvplaner.addlv_no_selected_lvs')}}
          </div>
        </div>
      </div>
    </div>
    <q-separator inset color="amber-4" class="q-mb-xl" />
    <div class="col-12 row justify-center q-ma-md">
      <div class="col-xs-12 col-md-10 row q-col-gutter-md">
        <div v-for="lv in this.selectedLv" :key="lv" class="col-12 text-h6 text-blue-8">
          {{ lv }}
        </div>
        <div class="col-xs-12 row q-col-gutter-md" v-if="this.filteredList && this.filteredList.length > 0">
        <div class="col-xs-12 col-md-6 col-lg-3 q-mt-md" style="max-width: 400px;" v-for="(course, i) in this.filteredList"
          :key="i">
          <q-expansion-item style="max-width: 500px;" class="shadow-1" default-opened header-class="text-grey-8">
            <template v-slot:header>
              <q-item-section avatar>
                <q-btn v-if="onCalendarLoaded && !this.getCourseStatus(course)" icon="add" padding="xs" round size="sm" color="blue-7" text-color="white"
                  @click.stop="addCourse(course)">
                  <q-tooltip>
                    {{getTooltipText(course)}}
                  </q-tooltip>
                </q-btn>
                <q-btn v-if="onCalendarLoaded && this.getCourseStatus(course)" icon="delete" padding="xs" round size="sm" color="negative" text-color="white"
                  @click.stop="removeCourse(course)">
                  <q-tooltip>
                    {{getTooltipText(course)}}
                  </q-tooltip>
                </q-btn>
              </q-item-section>

              <q-item-section>
                <div class="col-12 row">
                  <div class="col-12 row items-center"> {{ course.name }} <span
                      class="text-weight-bold q-ml-xs">({{ course.course_code }})</span>
                  </div>
                </div>
              </q-item-section>
            </template>
            <q-separator />
            <div class="row q-pa-sm">
              <div class="text-blue-7">
                <span>{{$t('lvplaner.addlv_prof')}}: </span>
                  <div class="text-grey-8" v-for="prof in course.taught_by" :key="prof">
                    {{ prof }}
                  </div>
              </div>
            </div>
            <q-separator />
            <div class="row justify-between q-pa-sm">
              <div class="text-blue-7" style="cursor: pointer">
                {{$t('lvplaner.addlv_mode')}}
                <q-tooltip>
                  {{ course.course_mode }}
                </q-tooltip>
              </div>
              <div class="text-blue-7" style="cursor: pointer">
                <a :href="course.vvz_url" target="_blank" class="text-blue-7" style="text-decoration: none">VVZ</a>
                <q-tooltip>
                  {{ course.comment }}
                </q-tooltip>
              </div>
              <div class="text-blue-7" style="cursor: pointer">
                {{$t('lvplaner.addlv_language')}}
                <q-tooltip>
                  {{ course.language }}
                </q-tooltip>
              </div>
            </div>
            <q-separator />
            <q-list padding separator>
              <q-item v-for="(date, i) in course.dates" :key="i">
                {{ formatDateRange(date.start, date.end) }},&nbsp;
                <span><a v-if="date.location !== 'null'" :href="date.location_url">{{ date.location }}</a></span>
              </q-item>
            </q-list>
          </q-expansion-item>
          </div>
        </div>
        <div class="col-12 text-center text-body1" v-else-if="this.selectedLv != 0">
          {{$t('lvplaner.addlv_no_offers')}}
        </div>
      </div>
    </div>
    <div class="col-12 row justify-center">
      <div class="col-12 q-mt-xl">
        <Calendar @loaded="onCalendarLoaded = true" />
      </div>
    </div>
  </div>
</template>

<script>
import { useLvStore } from "@/store/lv.store";
import { ref } from "vue";
import Calendar from "@/components/lvplaner/calendar.vue";
import { useQuasar } from "quasar";
export default {
  components: {
    Calendar,
  },
  setup() {
    const q = useQuasar();
    const lvStore = useLvStore();
    const options = ref([]);
    const filteredOptions = ref([]);
    
    return {
      lvStore: ref(lvStore),
      q,
      model: ref(null),
      options,
      filteredOptions,

      filterFn (val, update) {
        update(() => {
          if (val === '') {
            filteredOptions.value = options.value
          }
          else {
          const needle = val.toLowerCase()
          filteredOptions.value = options.value.filter(v => v.toLowerCase().indexOf(needle) > -1).slice().sort((a, b) => a.localeCompare(b, 'de', { sensitivity: 'base' }));
          }
        })
      },
    };
  },
  data() {
    return {
      selectedLv: ref([]),
      onCalendarLoaded: false,
    };
  },
  methods: {
    removeLvFromCart(index, name) {
      this.selectedLv = this.selectedLv.filter((item) => item !== name);
      this.lvStore.removeFromCart(index);
    },
    addToSelectedLv(name) {
      if (this.selectedLv.includes(name)) {
        const index = this.selectedLv.indexOf(name);
        this.selectedLv.splice(index, 1);
      } else {
        this.selectedLv.push(name);
      }
    },
    async getCourse(name) {
      await this.lvStore.addToCart(name);
      this.initializeSelectedLv()
      
    },
    async fetchCourseList() {
      const coursesList = await this.lvStore.searchCourse();

       coursesList.forEach(course => {
        if(!this.options.includes(course.name)) {
          this.options.push(course.name)
        }
      });
      this.options.sort((a, b) => a.localeCompare(b));
    },
    async initializeSelectedLv() {
      await this.fetchCourses();
      this.syncListAndSelectedLV();
    },
    async fetchCourses() {
      const lvNames = this.lvStore.cart.map((lv) => lv.name);
      if (lvNames.length > 0) {
        await this.lvStore.getCourses(lvNames);
      } else {
        this.lvStore.list = null;
        this.selectedLv = [];
      }
    },
    syncListAndSelectedLV() {
      if (this.lvStore.list) {
        const uniqueNames = new Set(this.lvStore.list.map((course) => course.name));
        this.selectedLv = Array.from(uniqueNames);
      } else {
        return;
      }
    },
    
    getCourseIcon(course) {
    if (this.lvStore.calendar) {
      const courseInCalendar = this.lvStore.calendar.find((item) => item.course_code === course.course_code);
      if (courseInCalendar) {
        return "check";
      } else {
        return "add";
      }
    } else {
      return "add";
    }
  },
  getCourseStatus(course) {
    if (this.lvStore.calendar) {
      const courseInCalendar = this.lvStore.calendar.find((item) => item.course_code === course.course_code);
      console.log(courseInCalendar)
      if (courseInCalendar) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  getCourseIconColor(course) {
    if (this.lvStore.calendar) {
      const courseInCalendar = this.lvStore.calendar.find((item) => item.course_code === course.course_code);
      if (courseInCalendar) {
        return "green-7";
      } else {
        return "blue-7";
      }
    } else {
      return "blue-7";
    }
  },
  getTooltipText(course) {
    if (this.lvStore.calendar) {
      const courseInCalendar = this.lvStore.calendar.find((item) => item.course_code === course.course_code);
      if (courseInCalendar) {
        return this.$t("lvplaner.addlv_removelv");
      } else {
        return this.$t("lvplaner.addlv_addlv");
      }
    } else {
      return this.$t("lvplaner.addlv_addlv");
    }
  
  },
    formatDateRange(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      const formattedStart = start.toLocaleString(undefined, options);
      const formattedEnd = end.toLocaleString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
      });
      return `${formattedStart}-${formattedEnd}`;
    },
    async addCourse(course) {
      await this.lvStore.storeCourse(course._id, this.q.notify);
      this.lvStore.fetchRegisteredCourses();
    },
    async removeCourse(course) {
      await this.lvStore.removeCourse(course._id, this.q.notify);
      this.lvStore.fetchRegisteredCourses();
    },
  },
  computed: {
    filteredList() {
      if (this.selectedLv.length == 0) {
        return;
      } else {
        return this.lvStore.list.filter((course) => {
          return this.selectedLv.includes(course.name);
        });
      }
    },
  },
  watch: {
    "lvStore.cart": {
      handler() {
        this.fetchCourses();
      },
      deep: true,
    },
  },
  async mounted() {
    await this.initializeSelectedLv();
    await this.fetchCourseList();
  },
};
</script>

<style lang="scss" scoped>
.q-item {
  padding: 8px 8px !important;
}

.badge {
  background-color: transparent;
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 2;
}

.selected-lv {
  background-color: $blue-2;
}
</style>
